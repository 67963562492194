/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
/* css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom styles */
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", Arial, sans-serif;
}

/* tailwind style */

/*  text */
.display_Text {
  @apply font-inter font-semibold text-slate-950 text-[28px] md:text-[48px] lg:text-[64px] leading-[38px] md:leading-[64px] lg:leading-[78px];
}

.heading1 {
  @apply font-inter font-semibold text-slate-950 text-[36px] leading-[52px] md:text-[44px] md:leading-[56px] lg:text-[48px] lg:leading-[60px];
}
.heading2 {
  @apply font-inter font-semibold text-slate-950 text-[28px] leading-[44px] md:text-[32px] md:leading-[44px] lg:text-[36px] lg:leading-[48px];
}
.heading3 {
  @apply font-inter font-semibold text-slate-950 text-[24px] leading-[28px] md:text-[28px] md:leading-[30px] lg:text-[28px] lg:leading-[32px];
}
.heading4 {
  @apply font-inter font-medium text-slate-950 text-[16px] leading-[24px] md:text-[18px] md:leading-[26px] lg:text-[20px] lg:leading-[28px];
}
.heading5 {
  @apply font-inter font-semibold text-slate-950 text-[16px] leading-[20px] md:text-[16px] md:leading-[22px] lg:text-[16px] lg:leading-[24px];
}
.heading6 {
  @apply font-inter font-semibold text-slate-950 text-[11px] leading-[16px] md:text-[12px] md:leading-[18px] lg:text-[13px] lg:leading-[20px];
}
.body-custom {
  @apply font-inter font-[300] text-slate-600 text-[18px] leading-[28px] md:text-[18px] md:leading-[30px] lg:text-[20px] lg:leading-[32px];
}
.body-large {
  @apply font-inter font-normal text-slate-600 text-[16px] leading-[28px] md:text-[18px] md:leading-[30px] lg:text-[20px] lg:leading-[32px];
}
.body-medium {
  @apply font-inter font-normal text-slate-600 text-[16px] leading-[20px] md:text-[16px] md:leading-[22px] lg:text-[16px] lg:leading-[24px];
}
.body-small {
  @apply font-inter font-normal text-slate-600 text-[12px] leading-[20px] md:text-[10px] md:leading-[20px] lg:text-[15px] lg:leading-[20px];
}
.caption {
  @apply font-inter font-normal text-slate-600 text-[12px] leading-[20px];
}
.caption-link {
  @apply font-inter font-normal text-blue_700 text-[12px] leading-[20px];
}
/* nav */
.nav-a {
  @apply  text-[24px] leading-[32px] md:text-[14px] md:leading-[14px]  text-['#666666']  hover:text-black
}
/* button */
.button {
  @apply justify-center items-center flex px-3 h-[44px] font-inter font-normal text-[13px]  sm:text-[15px] leading-[20px] bg-gradient-to-b from-[#363a44] to-[#030712]  text-white rounded-full border border-[#222222];
}
.button-outlined {
  @apply justify-center items-center flex px-3 h-[44px] font-inter font-normal text-[13px]  sm:text-[15px] leading-[20px] bg-gradient-to-b from-[#ffffff] to-[#f5f6f7] text-black rounded-full border border-[#d3d4d4];
}



/* animate */
@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 25s linear infinite;
  width: calc(169px * 5 + 12px * 5); /* Adjust based on logo size + gap */
}

.relative.overflow-hidden {
  white-space: wrap;
}

.flex-shrink-0 {
  flex-shrink: 0;
}


/* Button Animation - to delete*/ 

.snake-light-button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 30px;
  overflow: hidden;
}

.snake-light-button::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background: linear-gradient(90deg, transparent, #fff, transparent);
  filter: blur(1px);
  animation: snakeMove 4s linear infinite;
}

@keyframes snakeMove {
  0%, 100% {
      top: calc(100% - 1.5px);
      left: 0;
      width: 20px;
      height: 3px;
      transform: translateX(0);
  }
  24.99% {
      top: calc(100% - 1.5px);
      left: 0;
      width: 20px;
      height: 3px;
      transform: translateX(calc(100% - 20px));
  }
  25%, 25.01% {
      top: calc(100% - 1.5px);
      left: calc(100% - 1.5px);
      width: 3px;
      height: 20px;
      transform: translateY(0);
  }
  49.99% {
      top: 0;
      left: calc(100% - 1.5px);
      width: 3px;
      height: 20px;
      transform: translateY(calc(-100% + 20px));
  }
  50%, 50.01% {
      top: -1.5px;
      left: calc(100% - 1.5px);
      width: 20px;
      height: 3px;
      transform: translateX(0);
  }
  74.99% {
      top: -1.5px;
      left: calc(100% - 1.5px);
      width: 20px;
      height: 3px;
      transform: translateX(calc(-100% + 20px));
  }
  75%, 75.01% {
      top: -1.5px;
      left: -1.5px;
      width: 3px;
      height: 20px;
      transform: translateY(0);
  }
  99.99% {
      top: calc(100% - 20px);
      left: -1.5px;
      width: 3px;
      height: 20px;
      transform: translateY(0);
  }
}

/* shimmering glow */

.shimmering-button {
  background-color: #1e1e1e;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 30px;
  position: relative;
  border: 2px solid transparent;
  cursor: pointer;
  outline: none;
  overflow: hidden;
}

.shimmering-button::before {
  content: '';
  position: absolute;
  width: 12px; /* Size of the glowing dot */
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8); /* Color of the glowing dot */
  box-shadow: 0 0 12px rgba(255, 255, 255, 1);
  z-index: 2;
  animation: shimmer 8s linear infinite;
}

@keyframes shimmer {
  0% {
      top: 100%;
      left: 0%;
  }
  25% {
      top: 0%; /* Moves to the top */
      left: -19%; /* Moves to the left */
      transform: translate(0px, -8px); /* Adjust position */
  }
  50% {
      top: 0%; /* Stays at the top */
      left: 100%; /* Moves to the right */
      transform: translate(-0px, -8px); /* Adjust position */
  }
  75% {
      top: 100%;
      left: 100%;
      transform: translate(-0px, 0px); /* Adjust position */
  }
  100% {
      top: 100%;
      left: 0%;
      transform: translate(0px, 0px); /* Reset position */
  }
}

.guarantee-bold{
  color: #020617 !important;
  font-weight: 700;
}